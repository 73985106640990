import React, { useState } from "react";
import { useSelector } from "react-redux";
import coverImg from "../../utils/cover-img.jpg";
import "./contact.css";
import Loader from "../loader/Loader";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FaEnvelope, FaPhoneAlt } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import api from "../../api/api";

const Contact = () => {
  const cookies = new Cookies();
  const [details, setDetails] = useState({
    first_name: "",
    last_name: "",
    email: "",
    message: "",
  });
  const [errors, setErrors] = useState({});

  const setting = useSelector((state) => state.setting);
  const { t } = useTranslation();
  const placeHolderImage = (e) => {
    e.target.src = setting.setting?.web_logo;
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    if (!details.first_name.trim()) {
      newErrors.first_name = "First Name is required";
      valid = false;
    }

    if (!details.last_name.trim()) {
      newErrors.last_name = "Last Name is required";
      valid = false;
    }

    if (!details.email.trim()) {
      newErrors.email = "Email is required";
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(details.email)) {
      newErrors.email = "Email is invalid";
      valid = false;
    }

    if (!details.message.trim()) {
      newErrors.message = "Message is required";
      valid = false;
    } else if (details.message.trim().split(/\s+/).length < 50) {
      newErrors.message = "Message must be at least 50 words";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDetails({ ...details, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if(validateForm()){

      const { first_name, last_name, email, message } = details;
      if (cookies.get("jwt_token") !== undefined) {
        const formData = new FormData();
        formData.append('first_name', first_name);
        formData.append('last_name', last_name);
        formData.append('email', email);
        formData.append('message', message);
        api.getContactForm(cookies.get("jwt_token"), formData)
          .then((response) => {
            if (response.ok) {
              return response.json();
            }
            throw new Error("Network response was not ok.");
          })
          .then((result) => {
            if (result.status === 1) {
              toast.success(result.message);
              setDetails({
                first_name: "",
                last_name: "",
                email: "",
                message: "",
              });
            } else {
              toast.error("Error: " + result.message);
            }
          })
          .catch((error) => {
            toast.error("Error: " + error.message);
          });
      } else {
        toast.error("Error: JWT token is not defined.");
      }
    }else{
      
    }
  };

  return (
    <section id="contact-us" className="contact-us">
      {setting.setting === null ? (
        <Loader screen="full" />
      ) : (
        <>
          <div className="cover">
            <img
              onError={placeHolderImage}
              src={coverImg}
              className="img-fluid"
              alt="cover"
            ></img>
            <div className="title">
              <h3>{t("contact_us")}</h3>
              <span>
                {" "}
                <Link to="/" className="text-light text-decoration-none">
                  {t("home")} /
                </Link>{" "}
              </span>
              <span className="active">{t("contact_us")}</span>
            </div>
          </div>
          <div className="container">
            {/* <div className='contact-wrapper'  dangerouslySetInnerHTML={{ __html: setting.setting.contact_us }}> */}
            <div className="contact-main">
              <div className="contact_box">
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    <div className="contact_info_box">
                      <p>
                        For the most immediate assistance, reach out to our
                        dedicated support team at{" "}
                        <a href="mailto:support@soliket.com">
                          support@soliket.com
                        </a>{" "}
                        or contact us directly at{" "}
                        <a href="tel:07762358907">07762-358907</a>. We're always
                        here to help!
                      </p>

                      <div className="contact_info_inr">
                        <ul>
                          <li>
                            <div className="icon_box">
                              <FaPhoneAlt className="icon" />
                            </div>
                            <h3>
                              <a href="tel:07762358907">07762-358907</a>
                            </h3>
                          </li>
                          <li>
                            <div className="icon_box">
                              <FaEnvelope className="icon" />
                            </div>
                            <h3>
                              <a href="mailto:support@soliket.com">
                                support@soliket.com
                              </a>
                            </h3>
                          </li>
                          <li>
                            <div className="icon_box">
                              <FaLocationDot className="icon" />
                            </div>
                            <a
                              href="https://maps.google.com/?q=Raigarh,%20Chhattisgarh"
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ textDecoration: "none" }}
                            >
                              <h3>Raigarh (C.G.)</h3>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="contact_form">
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group position-relative">
                              <label>First Name</label>
                              <input
                                type="text"
                                name="first_name"
                                className={
                                  errors.first_name
                                    ? "form-control is-invalid"
                                    : "form-control"
                                }
                                placeholder="Enter your First name"
                                value={details.first_name}
                                onChange={handleChange}
                              />
                              {errors.first_name && (
                                <div className="invalid-feedback">
                                  {errors.first_name}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group position-relative">
                              <label>Last Name</label>
                              <input
                                type="text"
                                name="last_name"
                                className={
                                  errors.last_name
                                    ? "form-control is-invalid"
                                    : "form-control"
                                }
                                placeholder="Enter your Last name"
                                value={details.last_name}
                                onChange={handleChange}
                              />
                              {errors.last_name && (
                                <div className="invalid-feedback">
                                  {errors.last_name}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group position-relative">
                              <label>Email</label>
                              <input
                                type="text"
                                name="email"
                                className={
                                  errors.email
                                    ? "form-control is-invalid"
                                    : "form-control"
                                }
                                placeholder="Enter your Email address"
                                value={details.email}
                                onChange={handleChange}
                              />
                              {errors.email && (
                                <div className="invalid-feedback">
                                  {errors.email}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group position-relative">
                              <label>Message</label>
                              <textarea
                                name="message"
                                id=""
                                className={
                                  errors.message
                                    ? "form-control is-invalid"
                                    : "form-control"
                                }
                                rows="3"
                                placeholder="Enter your Message"
                                value={details.message}
                                onChange={handleChange}
                              ></textarea>
                              {errors.message && (
                                <div className="invalid-feedback">
                                  {errors.message}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group text-center m-0">
                              <button className="btn sub-btn">Submit</button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </section>
  );
};

export default Contact;
